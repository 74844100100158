<template>
  <div class="effectPrediction">
    <div class="main box box_column "  >
      <div class="mainTop box-bottom-border">
        <!-- 选择时间 -->
        <el-date-picker 
          type="datetime" 
          :clearable="false" 
          class="select-css"
          format="yyyy-MM-dd HH:mm"
          value-format="yyyy-MM-dd HH:mm:ss" 
          :picker-options="pickerStartTime"
          v-model="startTime">
        </el-date-picker>
        <span class="line"> - </span>
        <el-date-picker 
          type="datetime" 
          :clearable="false" 
          class="select-css"
          format="yyyy-MM-dd HH:mm"
          :picker-options="pickerEndTime"
          value-format="yyyy-MM-dd HH:mm:ss" 
          v-model="endTime"> 
        </el-date-picker>
        <!-- 选择测点 -->
        <el-form :model="formTop" :inline="true" style="margin-left: 20px;">
          <el-form-item label="测点" prop="pointValue">
            <el-select v-model="formTop.pointValue" placeholder="请选择" class="my-select">
              <el-option v-for="item in list" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <el-button @click="handleSearch" class="mlbtn">查询</el-button>
        <!-- <ul class="ul box center">
          <li v-for="(item, index) in list" :key="index" class="mR20 pointer"
            :class="active == item.id ? 'activeColor' : ''" @click="getTab(item)">{{ item.name }}</li>
        </ul> -->
      </div>
      <!-- <div>{{time[0]}}</div>
        <div>{{time[1]}}</div> -->
      <div class="mainBottom flex1" v-if="!isShowEmpty">
        <div class="box center main1" style="width: 100%; height: 100%">
          <div class="unitDiv">
            <div class="d1">{{ typeName }}：{{ unit }}</div>
          </div>
          <div id="effectBox"></div>
        </div>
      </div>
      <div class="noDataMain" v-if="isShowEmpty">
      <img src="../../../assets/images/noData.png" alt="" />
      <span>暂无数据</span>
    </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "effectPrediction",
  data() {
    return {
      isShowEmpty:true,
      formTop: {
        pointValue: ''
      },
      
      projectId: "",
      active: "",
      list: [],

      time: [],
      startTime: '',
      endTime: '',
      size: 10000,
      typeName: "",
      // time: [],
      // 开始时间
      pickerStartTime: {
        disabledDate: (time) => {
          let end = this.endTime
          if (end) {
            return time.getTime() > new Date(end).getTime()
          }
        }
      },
      // 结束时间
      pickerEndTime: {
        disabledDate: (time) => {
          let start = this.startTime
          if (start) {
            return time.getTime() < new Date(start).getTime()
          }
        }
      },
      y1: [],
      y2: [],
      unit: "",
      length: ""
    };
  },
  //创建
  created() {
    this.projectId = sessionStorage.getItem("projectId");
  },

  //安装
  mounted() {
    var x = [1, 2, 3, 4, 5, 6];
    var y1 = [0.1, 0.2, 0.3, 0.4, 0.5, 0.6];
    var y2 = [1.1, 2.2, 3.3, 1.4, 2.5, 3.6];
    var showed = false;

    // 时间获取
    var day = new Date();
    var day1 = day.getTime(); //获得当前时间戳
    var day11 = day1 + 1 * 24 * 60 * 60 * 1000;
    var day2 = day1 - 7 * 24 * 60 * 60 * 1000;

    // this.time2 = day.getTime();                   //当前时间戳
    // this.time1 = day1 - 10*24 * 60 * 60 * 1000;  //10个小时的时间戳

    var start = moment(day2).format("YYYY-MM-DD HH:mm:ss");
    var end = moment(day11).format("YYYY-MM-DD HH:mm:ss");

    console.log(start, end);
    this.time = [];
    this.time.push(start);
    this.time.push(end);

    // 三圣桥需求 时间获取
    this.getTimeValue()

    console.log("this.time1", this.time);
    this.listData(); //测点列表
    setTimeout(() => {
      if (this.list.length > 0) {
        this.$set(this.formTop, 'pointValue', this.list[0].id)
      }
    }, 200);
  },

  //方法
  methods: {
    // YYYY-MM-DD hh:mm:ss'转时间戳
    formDate(time) {
      const thisTime = time.replace(/ /g, "/");
      var nowTime = new Date(thisTime);
      nowTime = nowTime.getTime();
      return nowTime;
    },
    // 选择时间
    // handleStartTimeChange() {
    //   let time = this.formDate(this.startTime)
    //   this.endTime = moment(time + 7 * 24 * 60 * 60 * 1000).format("YYYY-MM-DD HH:mm:ss")
    // },
    // 初始时间赋值
    getTimeValue() {
      let end = new Date().getTime() + 1 * 24 * 60 * 60 * 1000
      this.endTime = moment(new Date(end)).format("YYYY-MM-DD HH:mm:ss")
      let start = new Date().getTime() - 7 * 24 * 60 * 60 * 1000
      this.startTime = moment(new Date(start)).format("YYYY-MM-DD HH:mm:ss")
    },
    // getTab(item) {
    //   console.log("item", item);
    //   if (this.active == item.id) {
    //     return;
    //   }

    //   this.active = item.id;
    //   this.unit = item.unit;
    //   this.typeName = item.typeName;

    //   // 时间获取
    //   var day = new Date();
    //   var day1 = day.getTime(); //获得当前时间戳
    //   var day11 = day1 + 24 * 60 * 60 * 1000;
    //   var day2 = day1 - 30 * 24 * 60 * 60 * 1000;

    //   // this.time2 = day.getTime();                   //当前时间戳
    //   // this.time1 = day1 - 10*24 * 60 * 60 * 1000;  //10个小时的时间戳

    //   var start = moment(day2).format("YYYY-MM-DD HH:mm:ss");
    //   var end = moment(day11).format("YYYY-MM-DD HH:mm:ss");

    //   console.log(start, end);
    //   this.time = [];
    //   this.time.push(start);
    //   this.time.push(end);

    //   this.offectData();
    //   this.historyData();
    // },

    listData() {
      this.$axios.get(`${this.baseURL}offect/select/finish/${this.projectId}`).then((res) => {
        console.log("测点列表", res.data);
        this.list = res.data.data;
        // this.list = [
        //   {
        //     id: 261,
        //     unit: "MPa",
        //     name: "YB-G2",
        //     typeName: "应力"
        //   },
        //   {
        //     id: 262,
        //     unit: "MPa",
        //     name: "YB-G4",
        //     typeName: "应力"
        //   }
        // ]
        console.log('typename,this.list', this.list && this.list.length)
        if (this.list && this.list.length) {

          this.active = this.list[0]["id"];
          this.unit = this.list[0]["unit"];
          this.typeName = this.list[0]["typeName"];
          this.offectData();
          this.historyData();
        }

      });
    },
    // 效应预测
    offectData() {
      console.log("this.time2", this.time);
      console.log('测点---------------',this.active)
      if (this.active) {
        // this.y2 = ['-2','-3','-2','-4','-2','-1','-2','-7','-2','-3'];
        this.$axios.get(`${this.baseURL}data/history/offect/${this.active}?sort=0&startTime=${this.time[0]}&endTime=${this.time[1]}&size=${this.size}`).then((res) => {
          console.log("效应数据-多", res.data.data);

          var data = res.data.data;
          this.y2 = [];
          // this.time = []
          data.forEach((item, index) => {
            if (this.time.indexOf(item.time) < 0) {
              this.time.push(item.time);
            }
            let itemFormat = moment(new Date(item.time).getTime()).format('YYYY-MM-DD HH:mm:ss')
            this.y2.push([itemFormat, item.data[0]]);
          });

          // console.log('y2-------------', this.y2);

          this.length = this.y2.length;
        });
      }
    },
    // 历史监测值 2022.11.21改为十分钟均值
    historyData() {
      console.log("this.time3", this.time);
      // interval 1一分钟均值 2十分钟均值 3一小时均值 4日均值
      if (this.active) {
        this.$axios.get(`${this.baseURL}data/history/raw/average/${this.active}?sort=0&interval=2&startTime=${this.time[0]}&endTime=${this.time[1]}&size=${this.size}`).then((res) => {
          console.log("历史均值数据---", res.data.data.data);

          var data = res.data.data.data;
          
          document.getElementById('effectBox').removeAttribute("_echarts_instance_");
          let orderTime = []
          if (data && data.length) {
            // this.time = [];
            this.y1 = [];
            data.forEach((item, index) => {
              if (this.time.indexOf(item.time) < 0) {
                this.time.push(item.time);
              }
              
              this.y1.push([item.time, item.data[2]]);
            });
            setTimeout(() => {
              orderTime = this.time.sort((a, b) => {
                return new Date(a).getTime() - new Date(b).getTime()
              })
              // 2022-11-22 要求预测时间显示更明显一些，均值时间轴人为拉长
              if (this.y1 && this.y1.length) {
                let averageLastTime = new Date(this.y1[this.y1.length - 1][0]).getTime()
                let y2LastTime = new Date(this.y2[this.y2.length - 1][0]).getTime()
                let ends = new Date(this.endTime).getTime()
                let now = new Date().getTime()
                let intervals = Math.abs((y2LastTime - averageLastTime)) / (10 * 60 * 1000) 
                console.log('---个数---',averageLastTime, y2LastTime, intervals)
                if (ends - now > 0) {
                  for(let i = 0; i <= intervals; i ++) {
                    orderTime.push(moment(averageLastTime + i * 10 * 60 * 1000).format('YYYY-MM-DD HH:mm:ss'))
                    this.time = orderTime
                  }
                  // console.log('---111000---最后一个时间节点', this.y1[this.y1.length - 1], this.y2[this.y2.length -1])
                }
              }
            }, 100);
          } else {
            console.log('当前均值无数据，查看预测值', this.y2)
            orderTime = []
            this.y2.forEach(item => {
              orderTime.push(item[0])
            })
          }
          setTimeout(() => {
            // console.log('最终的时间轴', orderTime)
            this.isShowEmpty = false
            this.$chart1.line2("effectBox", orderTime, this.y1, this.y2, "", 'false');
          }, 400);
          
          // setTimeout(() => {
          //   // console.log('time', this.time);
          //   // console.log('y1', this.y1);
          //   // console.log('y2', this.y2);
          //   var y = this.y1.slice(-this.length);
          //   var time = this.time.slice(-this.length);
          //   // console.log('y', y);
          //   this.y2.unshift("-");
          //   y.push("-");

          //   console.log("time----------", this.time.slice(-1));

          //   // var time1 = time[this.length-1];
          //   // console.log('time1',time1);
          //   var time1 = this.time.slice(-1);
          //   var day = new Date(time1);
          //   console.log("day", day);
          //   var day1 = day.getTime(); //获得当前时间戳
          //   var day11 = day1 + 10 * 60 * 1000;
          //   var start = moment(day11).format("YYYY-MM-DD HH:mm:ss");
          //   console.log("start", start);
          //   time.push(start);

          //   // console.log('time', time.length);
          //   var y2 = this.y2.splice(-time.length);

          //   console.log("y--------------------------", y);
          //   console.log("y1--------------------------", y2);
          //   console.log("y1--------------------------", this.y2);
          //   var showed = false;
          //   this.$chart1.line2("effectBox", time, y, y2, "", showed); //y this.y2
          // }, 300);
        });
      }
    },

    // 搜索
    handleSearch() {
      console.log('点击搜索', this.formTop.pointValue)
      let item = ''
      if (this.list.length > 0) {
        this.list.forEach(ele => {
          if (ele.id == this.formTop.pointValue) {
            item = ele
          }
        })
      }

      this.active = item.id;
      this.unit = item.unit;
      this.typeName = item.typeName;

      this.time = [];
      this.time.push(this.startTime);
      this.time.push(this.endTime);

      this.offectData();
      this.historyData();
    }
  }
};
</script>
<style scoped lang="scss">
.effectPrediction {
  width: 100%;
  height: 100%;
}
.mainTop {
  display: flex;
  margin-top: 20px;
}
.line {
  padding: 0 10px;
  margin-top: 5px;
}
::v-deep .el-date-editor {
  margin-top: 4px;
}

.mlbtn {
  margin-left: 20px;
  margin-top: 4px;
}

.select-css,
::v-deep .my-select input {
  height: 30px;
  line-height: 30px;
}

.main {
  width: 100%;
  height: 100%;
  // padding: 28px;
}

.ul li {
  padding: 6px 28px;
  border-radius: 20px;
  color: #6d81bf;
  background: #233361;
}

.mainBottom {
  padding: 49px 33px;
}

#effectBox {
  width: 100%;
  height: 100%;
}

.activeColor {
  color: #fff !important;
  background: #0050b4 !important;
}

//单位竖
.unitDiv {
  color: #4e5f8a;
  width: 22px;

  // position: relative;
  .d1 {
    transform: rotate(-90deg) translate(-20%, -50%);
    transform-origin: 0% 0%;
    position: absolute;
    left: 114px;
    // top: 50%;
    // left: 0;
    width: 150px;
  }
}

.main1 {
  background: #142654;
  border-radius: 24px;
  padding: 20px;
}
</style>
